import './Sidebar.sass';
import useWindowWidth from '../../hooks/useWindowWidth';
// import dataSites from '../../data.json';

export default function Sidebar({ handleOpen, handleCategory, handleResetSearch, handleToggleSidebar, handleScrollContainer }) {
   /*
   const arrCategories = dataSites.map(site => site.category),
         arrUniqueCategories = [].concat.apply([], arrCategories),
         categories = [...new Set(arrUniqueCategories)].sort();
   */

   const categories = [
      'HTML',
      'React',
      'Wordpress',
      'Alloggio',
      'Blog',
      'Business',
      'Design',
      'E-Commerce',
      'Moda e Bellezza',
      'Portfolio e Curriculum',
      'Ristoranti e Cucina',
      'Salute e Benessere',
      'Video',
      'Clienti'
   ];

   const windowWidth = useWindowWidth();
   const handle = e => {
      if(windowWidth < 830) {
         handleOpen(e);
      }
      handleResetSearch();
      handleCategory(e);
      handleScrollContainer();
   }

   return(
      <>
         { windowWidth > 830 ?
            <button
               id="sidebar-toggle"
               onClick={handleToggleSidebar}
            />
            : null
         }
         <h1 className="title">Categorie</h1>
         <ul className="categories">
            <li id="all" onClick={handle}>Tutti</li>
            {categories.map(category =>
               <li key={category} id={category} onClick={handle}>
                  {category}
               </li>
            )}
         </ul>
      </>
   );
}