export default function Navbar({ url }) {
   return(
      <nav>
         <ul>
            <li><a href={`${url}`}>Home</a></li>
            <li><a href={`${url}/cosa-offriamo/sitiweb/funzionalita`}>Funzionalità</a></li>
            <li><a href={`${url}/cosa-offriamo/sitiweb/prezzi`}>Prezzi</a></li>
            <li><a href={`${url}/area-clienti`}>Area Clienti</a></li>
         </ul>
      </nav>
   );
}