import React, { useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import './Header.sass';
import ToggleNav from './ToggleNav';
import Logo from './Logo';
import Navbar from './Navbar';
import ToggleTheme from './ToggleTheme';
import Sidebar from '../Sidebar/Sidebar';

const DOMAIN = 'https://milanesieditore.it';

export default function Header({ handleCategory, handleResetSearch }) {
   const windowWidth = useWindowWidth();

   const [openMobileNav, setOpenMobileNav] = useState(false);
   const handleOpen = e => {
      const ele = e.target;
      if(ele.id === 'navMobileOpen' || ele.className === 'nav-toggle' || ele.nodeName === 'LI') {
         setOpenMobileNav(!openMobileNav)
      }
   };

   if(windowWidth > 830) {
      return(
         <header>
            <Logo url={DOMAIN} />
            <div className="navbar">
               <Navbar url={DOMAIN} />
               <ToggleTheme />
            </div>
         </header>
      );
   } else {
      return(
         <header>
            <Logo url={DOMAIN} />
            
            <div className="nav-btns">
               <ToggleTheme />
               <ToggleNav isOpen={openMobileNav} handleOpen={handleOpen} />
            </div>
            
            <div id="navMobileOpen" data-visible={openMobileNav} onClick={handleOpen}>
               <div className="navMobilePannel">
                  <Navbar url={DOMAIN} />
                  <Sidebar
                     handleCategory={handleCategory}
							handleResetSearch={handleResetSearch}
                     handleOpen={handleOpen}
                  />
               </div>
            </div>
         </header>
      );
   }
}