import React, { useRef, useState } from 'react';
import useWindowWidth from './hooks/useWindowWidth';
import './sass/App.sass';
import Header from './component/Header/Header';
import Sidebar from './component/Sidebar/Sidebar';
import FirstSection from './component/CardSection/FirstSection';
import CardSection from './component/CardSection/CardSection';


export default function App() {
	const windowWidth = useWindowWidth();
	const refContainer = useRef();
	const handleScrollContainer = () => {
		refContainer.current.scrollTo({ top: 0, behavior: 'smooth' })
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	const [query, setQuery] = useState('all'),
			handleCategory = e => setQuery(e.target.id);

	const [querySearch, setQuerySearch] = useState(''),
			handleSearch = e => setQuerySearch(e.target.value),
			handleResetSearch = () => setQuerySearch('');

	const [quantity, setQuantity] = useState(0);
	const [orderBy, setOrderBy] = useState(''),
			handleOrderBy = e => setOrderBy(e.target.value);

	const [activePage, setActivePage] = useState(0),
			handleActivePage = e => setActivePage(parseInt(e.target.value));

	const [isOpenSidebar, setIsOpenSidebar] = useState(true),
			handleToggleSidebar = () => setIsOpenSidebar(!isOpenSidebar);
	
	const currentYear = new Date().getFullYear();
	
	return (
		<>
			<Header
				handleCategory={handleCategory}
				handleResetSearch={handleResetSearch}
			/>

			<main>
				{ windowWidth > 830 ?
					<div className="sidebar" data-visible={isOpenSidebar}>
						<Sidebar
							handleCategory={handleCategory}
							handleResetSearch={handleResetSearch}
							handleToggleSidebar={handleToggleSidebar}
							isOpenSidebar={isOpenSidebar}
							handleScrollContainer={handleScrollContainer}
						/>
					</div>
					: null
				}

				<div
					className="container"
					ref={refContainer} 
					data-full-width={isOpenSidebar}
				>

					{ windowWidth > 830 && !isOpenSidebar ?
						<button
							id="sidebar-toggle"
							aria-expanded={isOpenSidebar}
							onClick={handleToggleSidebar}
						/>	
						: null
					}

					<FirstSection
						activeCat={query}
						quantity={quantity}
						handleOrderBy={handleOrderBy}
						querySearch={querySearch}
						handleSearch={handleSearch}
						handleResetSearch={handleResetSearch}
					/>

					<CardSection 
						query={query}
						querySearch={querySearch}
						setQuantity={setQuantity}
						orderBy={orderBy}
						activePage={activePage}
						setActivePage={setActivePage}
						handleActivePage={handleActivePage}
						refContainer={refContainer}
						handleScrollContainer={handleScrollContainer}
					/>

					<footer>
						<small>
							&copy; Milanesi Editore {currentYear} - P.Iva 03463930929<br/><a href="https://www.iubenda.com/privacy-policy/68276502/legal" target="_blank" rel="noopener noreferrer">Privacy</a> e <a href="https://www.iubenda.com/privacy-policy/68276502/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
						</small>
					</footer>
				</div>
			</main>
		</>
	);
}