import React, { useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import lightMoon from '../../img/light-moon.svg';
import darkMoon from '../../img/dark-moon.svg';

export default function ToggleTheme() {
   const [themeLight, setTheme] = useLocalStorage('themeLight', true);

   useEffect(() => {
      if(!themeLight) {
         document.body.classList.add('dark');
      } else {
         document.body.classList.remove('dark');
      }
   }, [themeLight]);

   const toggleTheme = () => setTheme(!themeLight);
   
   return(
      <button id="toggle-theme" onClick={toggleTheme}>
         <img src={themeLight ? lightMoon : darkMoon} alt="Colore Tema" />
      </button>
   );
}