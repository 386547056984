import { useState } from "react";
import './ScrollToTop.sass'

export default function ScrollToTop({ refContainer, handleScrollContainer }) {
   const [visible, setVisible] = useState(false);
   
   const container = refContainer.current;
   const btnVisible = () => container.scrollTop > 200 ? setVisible(true) : setVisible(false);
   window.onload = () => container.addEventListener('scroll', btnVisible);

   return(
      <div id="goTop" onClick={handleScrollContainer} className={visible ? 'd-block' : ''}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 450"><path d="M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z"></path></svg>
      </div>
   );
}