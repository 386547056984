import React, { useEffect, useState } from 'react';
import './CardSection.sass';
import Pagination from './Pagination';
import ScrollToTop from './ScrollToTop';
import dataSites from '../../data.json';

export default function CardSection({ query, querySearch, setQuantity, setActivePage, orderBy, activePage, refContainer, handleActivePage, handleScrollContainer }) {
   const [filterData, setFilterData] = useState(dataSites);

   useEffect(() => handleScrollContainer(), [activePage, query]);

   useEffect(() => {
      setActivePage(0);

      if(query !== 'all' && querySearch === '') {
         setFilterData(dataSites.filter(site => site.category.includes(query)));
      } else if(querySearch !== ''){
         setFilterData(dataSites.filter(site => site.name.toLowerCase().includes(querySearch.toLowerCase())));
      } else {
         setFilterData(dataSites);
      }

      setQuantity(filterData.length);
   }, [query, querySearch, setQuantity, filterData.length]);

   let data = [];
   const pageSize = 9;
   for (let i=0; i < filterData.length; i+= pageSize) {
      const page = filterData.slice(i, i + pageSize);
      data.push(page);
   }
   
   switch(orderBy) {
      case 'A-Z':
         data[activePage].sort((a, b) => a.name.localeCompare(b.name));
         break;
      case 'Z-A':
         data[activePage].sort((a, b) => b.name.localeCompare(a.name));
         break;
      default:
         break;
   }

   return(
      <section className="card-container">
         {data[activePage] ?
            data[activePage].map(site =>
               <div className="card" key={site.name}>
                  <span className="browser-bar" />
                  <a href={site.url} target="_blank" rel="noopener noreferrer">
                     <img src={`./img/${site.name.toLowerCase().replaceAll(' ','-')}.webp`} loading="lazy" alt={site.name + ' - Libreria Milanesi Editore'} />
                  </a>
                  <div className="card-info">
                     <h2>
                        <a href={site.url} target="_blank" rel="noopener noreferrer">
                           {site.name}
                        </a>
                     </h2>
                     
                     <p>{site.category.join(', ')}</p>
                  </div>
               </div>
            )
            :
            <h3>Nessun risultato</h3>
         }

         <Pagination
            pages={data.length}
            activePage={activePage}
            handleActivePage={handleActivePage}
         />

         <ScrollToTop
            refContainer={refContainer}
            handleScrollContainer={handleScrollContainer}
         />
      </section>
   );
}