import './Pagination.sass';

export default function Pagination({ pages, activePage, handleActivePage }) {
   let numbers = Array.from({length: pages}, (_, i) => i + 1);
   const countPag = 3;

   if(numbers.length > countPag) {
      let idx = activePage < 2 ? 0 : activePage - 1;
      numbers = numbers.slice(idx, (activePage-1) + 3);
   }

   return (
      <div className="pagination">
         {activePage > countPag - 2 ?
            <button value="0" onClick={handleActivePage}>«</button>
            : null
         }

         {numbers.map(numb =>
            <button
               value={numb - 1}
               onClick={handleActivePage}
               key={numb}
               className={numb - 1 === activePage ? 'active' : null}
               >
               {numb}
            </button>
         )}

         {pages > countPag && activePage < pages - (countPag - 1) ?
            <button value={pages - 1} onClick={handleActivePage}>»</button>
            : null
         }
      </div>
   );
}