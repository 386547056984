import './FirstSection.sass';

export default function FirstSection({ activeCat, quantity, handleOrderBy, querySearch, handleSearch, handleResetSearch }) {
   return(
      <section>
         <h1 className="activeCat">{activeCat}</h1>

         <div className="flex">
            <p>Sono stati trovati {quantity} risultati.</p>
            {/*
            <div>
               <label htmlFor="orderBy">Ordina per: </label>
               <select name="orderBy" id="orderBy" onChange={handleOrderBy}>
                  <option value="none" defaultValue hidden>seleziona</option>
                  <option value="A-Z">A-Z</option>
                  <option value="Z-A">Z-A</option>
               </select>
            </div>
            */}

            <form onSubmit={e => e.preventDefault()}>
               <input type="text" placeholder="Cerca per Nome" value={querySearch} onChange={handleSearch} />
               <button type="submit">🔎︎ Cerca</button>

               {querySearch !== '' &&
                  <button type="reset" onClick={handleResetSearch}>✖ Reset</button>
               }
            </form>
         </div>
      </section>
   );
}